const Syarat=()=>{

    return(
        <div className="container-syarat d-flex flex-column">
                <h1>
                    Syarat Pendaftaran<br />LPK Mirai Akademi
                </h1>
                <div className="box">
                    <p>
                        <ul>
                            <li>Minimal Lulusan SMA/SMK sederajat</li>
                            <li>Pria / Wanita</li>
                            <li>Usia 18 s/d 35 tahun (saat pendaftaran)</li>
                            <li>Sehat Jasmani & Rohani (Lulus Medical Check)</li>
                            <li>Berat Badan Proporsional</li>
                            <li>Tidak bertato</li>
                            <li>Memiliki Sikap & Mental yang Baik</li>
                            <li>Tidak ada Ikatan dengan LPK atau Lembaga Penyalur Tenaga Kerja lainnya</li>
                            <li>Tidak berstatus sebagai karyawan atau pekerja (tidak ada ikatan kerja)</li>
                            <li>Dapat Izin dari Orang Tua/Keluarga</li>
                            <li>Wajib mengikuti Pendidikan TG (Tokutei Ginou) ± 6 Bulan</li>
                            <li>Siap tandatangan Kontrak Kerja</li>
                        </ul>
                    </p>
                </div>
            </div>

    );
}

export default Syarat;