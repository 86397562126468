
const Cta=()=>{
    return(
        <section class="cta">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 text-center">
              <h5>Kumpulan Berita & Kegiatan dari</h5>
              <h1>
                LPK Mirai Akademi
              </h1>
            </div>
          </div>
        </div>
      </section>
    )
}   

export default Cta;