import React, { useEffect, useState } from "react";
import apiClient from "../api";
import { Spinner } from "react-bootstrap";


export default function Program() {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const response = await apiClient.get("programs");
                setPrograms(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPrograms();
    }, []);

    return (
        <section className="visi" id="programx">
            <div className="container" >
                <div className="row">
                    <div className="col-lg-12 mt-2">
                        <h1 className="text-center">
                            <span className="gradient">Program</span>
                        </h1>

                    </div>
                    <div className="col-lg-12 mb-4">
                        <h2 className="text-center ">LPK MIRAI AKADEMI</h2>
                        <hr />
                    </div>
                </div>
                <div className="row" >
                    <div className="col-lg-6">
                        <h1 className="text-center mb-4">{programs.title}</h1>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: programs.content,
                            }}
                        />
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center">
                        <img
                            src={programs.image_full}
                            alt="Tokutei Ginou"
                            className="img-fluid "
                            style={{ borderRadius: '15px' }}
                            
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6  d-flex justify-content-center">
                    <a href="https://bit.ly/PendaftaranLPKMiraiAkademi" target='_blank'>
                    <button className="btn btn-primary mt-2 btn-lg">JOIN NOW</button></a>
                    </div>
                    
                </div>
            </div>

        </section>
    )
};