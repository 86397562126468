import React from 'react';
import  { useEffect, useState } from "react";
import apiClient from "../api";
import { Link } from 'react-router-dom';

import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
import Syarat from './syarat';
import { Button } from 'bootstrap';

export default function Gallery() {

    const [galeri,setGaleri]=useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            const response = await apiClient.get('gallerys');
            setGaleri(response.data);
            // setLoading(false);
          } catch (err) {
            console.log(err.message);
            // setLoading(false);
          }
        };
    
        fetchBlogs();
      }, []);


    return (
        <section className="section-gallery">
            <Syarat />           

            <h1 id="galeri">
                Galeri<br />LPK Mirai Akademi
            </h1>
            <div className="container">
                <div className="gallery">
                    {galeri.slice(0,16).map((item, index) => (
                    <a href={item.image} data-lightbox="models" data-title={item.caption}>
                        <img src={item.image} alt={item.caption} />
                    </a>
                    ))}
                   
                </div>
                
            </div>
            <div className="container">
                  <Link to="/all-gallery"><button type="button" className="btn btn-primary">Selengkapnya ...</button> </Link> {/* Ganti '/all-images' dengan rute yang sesuai */}
                </div>
        </section>
    )
}
