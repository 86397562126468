import { Button } from "react-bootstrap";
import apiClient from "../api";
import { useState, useEffect } from "react";

const Profile=()=>{

    const [profile, setProfile] = useState([]);

    useEffect(() => {
      const fetchPrograms = async () => {
        try {
          const response = await apiClient.get("profiles");
          setProfile(response.data[0]);
        //   console.log(response);
        } catch (err) {
          console.log(err);
          
        }
      };
  
      fetchPrograms();
    }, []);
  
    // console.log(profile);
    return (
        <section className="profil" id="profil">
            <div className="container">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        <img src={profile?.image} alt="" />
                    </div>
                    <div className="col-lg-6" data-aos="fade-up">
                        <h1>
                            Profil<br />LPK Mirai Akademi
                        </h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: profile?.content
                          }}
                        />
                        <a href="https://bit.ly/PendaftaranLPKMiraiAkademi"><Button  className="btn btn-orange">JOIN NOW</Button></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Profile;