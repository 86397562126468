import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Berita from "./Berita";
import Footer from "./footer";
import Cta from "./CtaBerita";

const News=()=>{
    return(
        <div className="page-berita">        
        <Helmet>
            News | LPK Mirai Akademi
        </Helmet>

            <Cta />
            <Berita />
            <Footer />
        
        </div>
    )
}


export default News;